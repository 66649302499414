var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsxs as _jsxs, jsx as _jsx } from "react/jsx-runtime";
import { LazyMotion, m } from 'framer-motion';
// ----------------------------------------------------------------------
// eslint-disable-next-line import/extensions
var loadFeatures = function () { return import('./features.js').then(function (res) { return res.default; }); };
export default function MotionLazyContainer(_a) {
    var children = _a.children;
    return (_jsx(LazyMotion, __assign({ strict: true, features: loadFeatures }, { children: _jsxs(m.div, __assign({ style: { height: '100%' } }, { children: [" ", children, " "] })) })));
}
