var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import { m } from 'framer-motion';
// @mui
import { Box } from '@mui/material';
// hooks
import useResponsive from '../../hooks/useResponsive';
//
import { varContainer } from './variants';
export default function MotionViewport(_a) {
    var children = _a.children, _b = _a.disableAnimatedMobile, disableAnimatedMobile = _b === void 0 ? true : _b, other = __rest(_a, ["children", "disableAnimatedMobile"]);
    var isMobile = useResponsive('down', 'sm');
    if (isMobile && disableAnimatedMobile) {
        return _jsx(Box, __assign({}, other, { children: children }));
    }
    return (_jsx(Box, __assign({ component: m.div, initial: "initial", whileInView: "animate", viewport: { once: true, amount: 0.3 }, variants: varContainer() }, other, { children: children })));
}
